import { Subject, BehaviorSubject } from "rxjs";
import { distinctUntilChanged, auditTime } from "rxjs/operators";
import { EditorClip } from "./editorClip";
import { EditorLib, imageCanvasGenerator } from "./editorLib";
import { EditorTimeLineConfig } from "../../timeline/timeline.component";

interface PlayTime {
	library: EditorLib,
	position: number,
}

/**
 * used to storage clips and libraries
 * manages events 
 */
class EditorService {
	timeLineConfig: EditorTimeLineConfig
	bumperIn: EditorClip;
	bumperOut: EditorClip;
	constructor(type: 'absolute' | 'relative' = 'absolute') {
		if (type === 'absolute') {
			this.timeLineConfig = {
				type,
				detail: {
					visible: true,
					start: Date.now() - 600000,
					end: Date.now() + 600000,
				},
				master: {
					visible: true,
					start: 0,
					end: 0,
				}
			}
			let m = new Date()
			m.setHours(0);
			m.setMinutes(0);
			m.setSeconds(0);
			m.setMilliseconds(0);
			this.timeLineConfig.master.start = m.getTime();
			this.timeLineConfig.master.end = m.getTime() + 24 * 3600 * 1000
		} else {
			this.timeLineConfig = {
				type,
				detail: {
					visible: true,
					start: 0,
					end: 3 * 3600 * 1000,
				},
				master: {
					visible: true,
					start: 0,
					end: 5 * 60 * 1000,
				}
			}
		}
		this.addLibrary$.pipe(auditTime(10)).subscribe(() => {
			this.clips.forEach(clip => {
				clip.endLib = this.getLibraryForTime(clip.end)
				clip.startLib = this.getLibraryForTime(clip.start)
			})
		})
	}
	destroy() {
		this.addLibrary$.complete();
		this.clearClips();
		this.clearLibraries();
	}
	getPlayTime(): number {
		return this.playTime$.getValue().library.start + this.playTime$.getValue().position;
	}
	moveCurrentEnd(delta: number = 1000) {
		const c = this.selected;
		if (c) {
			c.end += delta;
		}
	}
	moveCurrentStart(delta: number = 1000) {
		const c = this.selected;
		if (c) {
			c.start += delta;
		}
	}
	playCurrentEnd() {
		const c = this.selected;
		if (c) {
			this.playToTime(c.end - 2000)
			this.stopTime = c.end;
		}
	}
	stopTime: number;
	playCurrentStart() {
		const c = this.selected;
		if (c) {
			this.playToTime(c.start)
			this.stopTime = c.end;
		}
	}
	selected$ = new BehaviorSubject<EditorClip>(null)
	set selected(clip) {
		if (this.selected !== clip) {
			if (this.selected) this.selected.selected = false;
			this.selected$.next(clip)
		}
	}
	get selected() {
		return this.selected$.getValue()
	}
	updateClip(_id: string, _start: Date, _end: Date, _selected: boolean, _arg4: any) {
		throw new Error("Method not implemented.");
	}
	getLibraryForTime(time: number) {
		for (let i = 0; i < this.libraries.length; i++) {
			const l = this.libraries[i];
			const lend = l.start + l.duration$.getValue();
			if (l.start <= time && time <= lend) { return l; }
		}
		return null;
	}

	getLibrariesForTimeAndLibId(time: number, lib: EditorLib = null) {
		return ((lib && lib.start <= time && time <= lib.end) ? lib : this.getLibraryForTime(time))
	}

	playToTime(time: number) {
		const currentLib = this.getLibraryForTime(time);
		if (currentLib) {
			this.play$.next({
				library: currentLib,
				position: time - currentLib.start,
			})
		} else {
			console.error(`no library found for time ${time}`)
		}
	}
	// store current play time
	playTime$ = new BehaviorSubject<PlayTime>(null)
	updatePlay(position: number) {
		const playTime = this.play$.getValue();
		if (playTime) {
			this.playTime$.next({
				library: playTime.library,
				position: position,
			})
		} else {
			console.error('there is no current library setup, nowhere to go')
		}
	}
	// this is to call playservice
	play$ = new BehaviorSubject<PlayTime>(null);
	play(id: string = null, position: number = null) {
		let currentLib = null;
		if (id === null) {
			const c = this.playTime$.getValue();
			if (c) {
				const tp = c.library.start + c.position;
				currentLib = this.libraries.find(l => l.start <= tp && tp <= l.end);
				if (currentLib) {
					position = tp - currentLib.start;
				}
			}
			if (!currentLib && this.libraries.length > 0) {
				currentLib = this.libraries[0];
				this.libraries.forEach(f => { if (currentLib.end < f.end) currentLib = f; })
				position = currentLib.end;
			}
		} else {
			currentLib = this.libraries.find(l => l.id === id);
		}
		if (currentLib) {
			this.play$.next({
				library: currentLib,
				position: position,
			})
		} else {
			console.error('nowhere to play');
			this.play$.next(null);
		}
	}
	clearLibraries() {
		console.log('CLEAR LIBS')
		this.libraries.slice().forEach(l => l.destroy())
		this.libraries.length = 0;
		this.clips.forEach(c => { c.startLib = null; c.endLib = null });
		this.play$.next(null);
	}
	// getLibraryById(id: string) {
	// 	return this.libraries.find(l => l.id === id);
	// }

	// bumpers
	addBumber(libraryId: string, libraryDuration: number, libraryName: string, relativePosition: number) {
		const bumper = new EditorClip(0, libraryDuration, false)
		bumper.type = 'bumper';
		bumper.title = `Cortina salida: ${libraryName}`
		bumper.endLib = bumper.startLib = new EditorLib(libraryId, 0, libraryDuration, null, null, null);
		bumper.relativePosition = relativePosition;
		if (relativePosition === 0) {
			this.bumperIn = bumper;
		} else {
			this.bumperOut = bumper;
		}
	}
	removeBumper(clip: EditorClip) {
		if (this.bumperOut === clip) {
			this.bumperOut = null;
		} else if (this.bumperIn === clip) {
			this.bumperIn = null;
		} else {
			console.warn('trying to remove bumper that is not bumper in or out')
		}
	}

	/**
	 * CLIPS
	 */
	readonly clips = new Array<EditorClip>()
	public readonly libraries = new Array<EditorLib>()
	addClip$ = new Subject<EditorClip>();
	addClip(start: number, duration: number, selected: boolean = false) {
		const clip = new EditorClip(start, duration, selected)
		if (selected) {
			this.selected = clip;
		}
		this.clips.push(clip);
		this.clips.sort((left: EditorClip, right: EditorClip) => left.start - right.start);
		clip.selected$.pipe(distinctUntilChanged()).subscribe(selected => {
			if (selected) {
				this.selected = clip;
			} else {
				this.selected = null
			}
		})
		clip.end$.subscribe((end) => {
			this.detectOverlapedClips()
			if (!clip.endLib || clip.endLib.start >= end || end >= clip.endLib.end) {
				clip.endLib = this.getLibrariesForTimeAndLibId(end, clip.startLib)
			}
		})
		clip.start$.subscribe({
			next: (start) => {
				this.detectOverlapedClips()
				if (!clip.startLib || clip.startLib.start >= start || start >= clip.startLib.end) {
					clip.startLib = this.getLibrariesForTimeAndLibId(start, clip.endLib)
				}
			},
			complete: () => {
				const index = this.clips.findIndex(c => c === clip);
				if (index >= 0) {
					const c = this.clips.splice(index, 1);
					if (c.length === 1) {
						c[0].destroy()
					}
				}
			}
		})
		this.addClip$.next(clip);
		return clip
	}
	removeClip(id: string) {
		const found = this.clips.find(c => c.id === id)
		if (found) {
			found.destroy();
			this.detectOverlapedClips();
		}
	}
	clearClips() {
		this.bumperIn = null;
		this.bumperOut = null;
		this.clips.slice().forEach(c => c.destroy())
	}

	/**
	 * OTHER
	 */

	copyToMedia(_clip) {
		console.error('TODO!1')
		// const media = {
		// 	title: clip.title,
		// 	description: clip.description,
		// 	tags: [],
		// 	clips: [{
		// 		start: clip.start,
		// 		end: clip.end,
		// 	}],
		// };
		// this.$scope.selectTab(0);
		// this.$rootScope.$broadcast('copy_media', media);
	}

	addLibrary$ = new Subject<EditorLib>()

	appendRelativeLibrary(id: string, duration: number, url: string, masterUrl: string, getImageCanvas: imageCanvasGenerator<any>, title: string) {
		// adding 1 milliseconds so libs can be apart, lib.end !== nextLib.start 
		let libStart = this.libraries.reduce((duration, lib) => { return duration + lib.duration }, 0);
		if (libStart !== 0) libStart += 1;
		const newLib = new EditorLib(id, libStart, duration, url, masterUrl, getImageCanvas);
		newLib.title = title;
		const maxDetailInitialRange = 5 * 60 * 1000;
		if (this.libraries.length === 0) {
			this.timeLineConfig.detail = {
				visible: this.timeLineConfig.detail.visible,
				start: 0,
				end: newLib.duration > maxDetailInitialRange ? maxDetailInitialRange : newLib.duration
			}
		}

		this.timeLineConfig.master = {
			visible: this.timeLineConfig.master.visible,
			start: 0,
			end: libStart + newLib.duration
		}

		this.libraries.push(newLib);
		this.addLibrary$.next(newLib);
	}

	addLibrary(refId: string, start: number, duration: number, url: string, masterUrl: string, getImageCanvas: imageCanvasGenerator<any>, title: string = null, update = true) {
		const foundLib = this.libraries.find(l => l.refId === refId);
		if (!update || !foundLib) {
			const newLib = new EditorLib(refId, start, duration, url, masterUrl, getImageCanvas);
			if (title !== null) newLib.title = title
			this.libraries.push(newLib);
			this.addLibrary$.next(newLib);
		} else {
			foundLib.duration = duration;
			foundLib.start = start;
		}
	}


	/**
	 * Squash libraries so the're no gaps between each other
	 */
	adjustRelativeLibraries() {
		if (this.timeLineConfig.type === 'relative') {
			//assume there is no library superposition 
			this.libraries.sort((a, b) => a.start - b.start);
		}
	}

	adjustRelativeTimeLine() {
		if (this.timeLineConfig.type === 'relative') {
			// this.libraries.reduce((duration, ))
		}
	}

	removeLibrary(lib: EditorLib) {
		const index = this.libraries.findIndex(l => l.id === lib.id);
		if (index >= 0) {
			const removed = this.libraries.splice(index, 1);
			removed[0].destroy();
			if (this.timeLineConfig.type === 'relative') {
				this.adjustRelativeLibraries();
				this.adjustRelativeTimeLine();
			}
		}
	}



	/**
	 * <----A----><--B-->
	 * <--B--><----A---->
	 * B.start = A.start
	 * A.start = B.end
	 */
	move(libA: EditorLib, amount: number) {
		const index = this.libraries.findIndex(l => l.id === libA.id);
		let libB: EditorLib;

		// TODO: Handle cases when clips overlap
		if (this.clips.length > 0) {
			if (!confirm('Hay un clip extendido entre una de las librerías que desea mover. Mover la librería eliminará el clip. ¿Desea continuar?')) {
				return
			}
			this.clearClips();
		}

		if (amount > 0) {
			libB = this.libraries[index + amount];
		} else {
			libB = libA;
			libA = this.libraries[index + amount];
		}
		if (!libA || !libB) {
			alert('AAAAAAA')
			return
		}

		libB.start = libA.start;
		libA.start = libB.end + 1;

		this.adjustRelativeLibraries();
	}

	modifyLibrary(refId: string, start: number, duration: number) {
		const foundLib = this.libraries.find(l => l.refId === refId)
		if (foundLib) {
			foundLib.duration = duration;
			foundLib.start = start;
		} else {
			console.error('no lib found to modify in editor service', refId)
		}
	}

	getTimeLineConfigJSON(): FireClip.TimeLineConfig {
		return {
			type: this.timeLineConfig.type,
			detail: this.timeLineConfig.detail,
			master: this.timeLineConfig.master,
			libraries: this.libraries.map(l => ({
				id: l.refId,
				start: l.start
			}))
		}
	}

	/**
	 * [] : A Clip
	 * <> : A Library
	 * 
	 * -------<-------lib------->-------
	 * ----[  A  ]---[ B ]---[  C  ]---- A,C: fixable; B: OK
	 * -----[          D          ]----- D: fixable
	 * -[ E ]---------------------[ E ]- E: error
	 */

	private caseBorC(lib, clipStart: number) {
		return (lib.start <= clipStart && clipStart <= lib.end);
	}

	private caseBorA(lib, clipEnd: number) {
		return (lib.start <= clipEnd && clipEnd <= lib.end);
	}

	private caseB(lib, clip) {
		return this.caseBorC(lib, clip.start) && this.caseBorA(lib, clip.end);
	}

	private caseD(lib, clip) {
		return (lib.start > clip.start && clip.end > lib.end);
	}

	// private caseAorBorCorD(lib, clip) {
	// 	return this.caseBorC(lib, clip.start) || this.caseBorA(lib, clip.end) || this.caseD(lib, clip);
	// }

	// private caseAorCorD(lib, clip) {
	// 	return this.caseAorBorCorD(lib, clip) && !this.caseB(lib, clip);
	// }

	fixClip(clip: EditorClip) {
		if (!clip.errored) {
			console.error("El clip a corregir no tiene errores")
			return
		}

		if (clip.clipConflictError) { clip = this.mergeCollidedClips(clip) }
		if (clip.libraryConflictError) { this.splitClip(clip) }
	}

	private splitClip(clip: EditorClip) {
		clip.destroy()

		this.libraries
			.forEach((lib) => {
				if (this.caseB(lib, clip)) {
					this.addClip(clip.start, clip.end - clip.start)
				} else if (this.caseBorA(lib, clip.end)) {
					this.addClip(lib.start, clip.end - lib.start)
				} else if (this.caseBorC(lib, clip.start)) {
					this.addClip(clip.start, lib.end - clip.start)
				} else if (this.caseD(lib, clip)) {
					this.addClip(lib.start, lib.end - lib.start)
				}
			});
		this.fitClips()
	}

	private fitClips() {
		this.sort();
		for (let index = 1, size = this.clips.length; index < size; index++) {
			const prevClip = this.clips[index - 1];
			const clip = this.clips[index];
			if (clip.start && prevClip.end && clip.start < prevClip.end) {
				prevClip.end = clip.start;
			}
		}
	}
	private sort() {
		this.clips.sort((a, b) => {
			if (a.relativePosition === -1 || b.relativePosition === 0) return 1;
			if (a.relativePosition === 0 || b.relativePosition === -1) return -1;
			return a.start - b.start
		});
	}

	private mergeCollidedClips(clip: EditorClip) {
		const clipsCollisioned = this.clips.filter(c => clip.collides(c))
		const newStart = Math.min(...clipsCollisioned.map(c => c.start))
		const newEnd = Math.max(...clipsCollisioned.map(c => c.end))
		clipsCollisioned.forEach(c => c.destroy())
		return this.addClip(newStart, newEnd - newStart)
	}

	private detectOverlapedClips() {
		let overlapedClipsIds = new Array<string>()
		for (let i = 0; i < this.clips.length - 1; i++) {
			const a = this.clips[i];
			for (let j = i + 1; j < this.clips.length; j++) {
				const b = this.clips[j];
				if (a.collides(b)) {
					overlapedClipsIds.push(a.id, b.id)
				}
			}
		}
		overlapedClipsIds = [...new Set(overlapedClipsIds)]
		this.clips.forEach(clip => clip.clipConflictError = overlapedClipsIds.includes(clip.id))
	}
}


export { EditorService, EditorClip, EditorLib }