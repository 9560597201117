import fireclipApp from '../../app';
import socketService from '../socketService';
import { EventEmitter } from "events";
import * as angular from 'angular';
import Thumbnail from '../../timeline/thumbnail';
import { getTileImageAsCanvas } from '../../utils';
import { transformRawLib } from './library';
import { resolveThumbnailTemplate } from "../utils"

const path = require('path')

export declare interface LibraryService {
	on(event: 'libraryAdded', listener: (clip: FireClip.Library) => void): this;
	emit(event: 'libraryAdded', clip: FireClip.Library): boolean;
	on(event: 'libraryUpdated', listener: (clip: FireClip.Library) => void): this;
	emit(event: 'libraryUpdated', clip: FireClip.Library): boolean;
}

export class LibraryService extends EventEmitter {
	jobChange: any;
	libraries: FireClip.Library[] = [];
	activeRecordingId: string = null;
	private recordingChange: any;
	listenToAdd = true;
	constructor(private Library, private $rootScope) {
		'ngInject';
		super();
		this.jobChange = this.onJobChange.bind(this);
	}
	async getImage(library: FireClip.Library, time: number, imageService: Thumbnail) {
		try {
			const secsFromStart = Math.floor(time / 1000);
			const spriteNum = Math.floor(secsFromStart / (library.thumbnailsV2.verticalCount * library.thumbnailsV2.horizontalCount) + 1);
			let fileName = resolveThumbnailTemplate(library.thumbnailsV2.fileTemplate, spriteNum);
			let newUrl = theConfig.library_storage_url + path.join('/', library.path, library.thumbnailsV2.path, fileName);
			const image = await imageService.getImage(newUrl);
			const tileNum = Math.floor((time / 1000)) % (library.thumbnailsV2.verticalCount * library.thumbnailsV2.horizontalCount);
			if (image) {
				const canvas = getTileImageAsCanvas(image, library.thumbnailsV2.horizontalCount, library.thumbnailsV2.verticalCount, tileNum)
				return canvas;
			} else {
				return null
			}
		} catch (error) {
			const canvas = document.createElement('canvas');
			//168x126 = 4:3, 224x126 =  16:9// pixeles cuadrados
			canvas.width = 160; //160
			canvas.height = 120; //90
			canvas.getContext('2d').fillStyle = 'red';
			canvas.getContext('2d').fillRect(0, 0, 160, 120);
			return canvas;
		}
	}
	save(library) {
		return this.Library.update({ id: library.id }, {
			title: library.title,
		}).$promise
	}

	/**
	 * starts websocket subscription to @recordingId and clears subscription if one exists
	 */
	startSubscription(recordingId: string, listenToAdd = true) {
		this.listenToAdd = listenToAdd
		if (this.activeRecordingId) {
			this.clearSubscritionAndLibraries(this.activeRecordingId);
		}
		this.activeRecordingId = recordingId;
		this.recordingChange = (params) => {
			this.onRecordingChange(recordingId, params);
		};
		socketService.onJobChange(this.jobChange);
		socketService.onRecording(this.recordingChange);
		console.log(`startSubscritionAndLibraries ${recordingId}`)
		socketService.subscribeToRecordingId(recordingId);
	}
	/**
	 * ends websocket subscription to recording and clear library storage
	 */
	clearSubscritionAndLibraries(recordingId: string) {
		console.log(`clearSubscritionAndLibraries ${recordingId}`)
		this.listenToAdd = true;
		socketService.offJobChange(this.jobChange)
		socketService.offRecording(this.recordingChange);
		socketService.unsubscribeToRecordingId(recordingId);
		this.activeRecordingId = null;
	}
	onJobChange(job) {
		if (!job.library) {
			return
		}
		const library = this.libraries.find(m => m.id == job.library);
		if (!library) {
			console.error('no library found')
			return;
		}
		if (job.root) {
			let theExport = library.exports.find(j => j.id == job.root);
			if (!theExport) {
				// run in circles, or create a dummy :D
				theExport = {
					id: job.root,
					childs: [job]
				}
				library.exports.push(theExport);
			} else {
				if (!theExport.childs || theExport.childs.length === 0) {
					theExport.childs = [job]
				} else {
					const foundJob = theExport.childs.find(j => j.id === job.id)
					Object.assign(foundJob, job);
				}
			}
		} else { // it's the root job aka, the export
			const theExport = library.exports.find(j => j.id == job.id);
			if (theExport)
				Object.assign(theExport, job);
			else
				library.exports.push(job); // push this new exporter...
		}
		return this.$rootScope.$digest();
	}

	async getLibraryByRecordingIds(recordings: string[], ids: string[], titleSearch = "", page = 0, limit = 10): Promise<FireClip.Library[]> {
		return this.Library.query({
			recording: recordings,
			id: ids,
			limit: limit,
			skip: page * limit,
			title: titleSearch,
			sort: 'createdAt DESC',
			trash: false
		}).$promise
	}

	async queryAndAddLibrariesToStore(recordingId: string, start: Date = null, end: Date = null): Promise<FireClip.Library[]> {
		if (!recordingId || !start || !end) return [];
		return this.Library.query({
			start: start,
			end: end,
			recording: recordingId,
			sort: 'start DESC',
			trash: false
		}).$promise
	}

	async findByIds(libIds: string[]): Promise<FireClip.Library[]> {
		return this.Library.query({ id: libIds, trash: false }).$promise;
	}

	async findById(id): Promise<FireClip.Library> {
		return this.Library.get({ id: id }).$promise
	}
	getLibraryForTime(time: number) {
		for (let i = 0; i < this.libraries.length; i++) {
			const l = this.libraries[i];
			const lend = l.start.getTime() + l.duration * 1000;
			if (l.start.getTime() <= time && time <= lend) { return l; }
		}
		return null;
	}

	getLibraryById(id: string): FireClip.Library {
		return this.libraries.find(l => l.id === id)
	}

	/**
	 * on websocket change
	 */
	private onRecordingChange(recordingId: string, params: { attribute: string; added: any; updated: any; verb: string; }) {
		if (params.attribute === 'libraries') {
			const library: FireClip.Library = params.added || params.updated;
			transformRawLib(library)
			library.start = new Date(library.start || Date.now());
			library.end = new Date(library.end);
			if (library.recordingId != recordingId) {
				socketService.unsubscribeToRecordingId(library.recording);
				return;
			}
			if (params.verb == 'addedTo') {
				if (this.listenToAdd) {
					this.libraries.unshift(library);
					this.emit('libraryAdded', library);
				}
			} else if (params.verb === 'updated') {
				const libraryToUpdate = this.libraries.find(item => item.id == library.id);
				if (libraryToUpdate) {
					angular.merge(libraryToUpdate, library);
					this.emit('libraryUpdated', libraryToUpdate);
				} else {
					if (this.listenToAdd) {
						this.libraries.unshift(library);
						this.emit('libraryAdded', library);
					}
				}
			}
		}
	}

	public getVodURL(library: FireClip.Library, _recording: FireClip.Recording) {
		const processExport = library.exports?.find(e => e.type === 'process_library');
		if (processExport) {
			return theConfig.media_storage_url + processExport.result?.packager?.element;
		} else {
			let filePath = path.join('/', library.path, 'stream', `${library.filePrefix}.m3u8`);
			return theConfig.library_storage_url + filePath;
		}
	}
	public getCurrentLibraryUrl(library) {
		if (library) {
			const extension = library.ext || '.mpeg';
			const filePath = path.join('/', library.path, `${library.filePrefix}${extension}`);
			return theConfig.library_storage_url + filePath;
		}
		return '';
	}
}

fireclipApp.service('LibraryService', LibraryService);